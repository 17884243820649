<template>
  <!--  <section>-->
  <!--    <CampusHeader-->
  <!--        :can-create="false"-->
  <!--        :activeTab="0"-->
  <!--        @start-creating="startCreatingApplication"-->
  <!--        :manage-campuses="true"-->
  <!--        :can-all="true"-->
  <!--        :header-text="'Lessons'"-->
  <!--        :create-text="'New Lesson'"-->
  <!--        :header_tabs="[{ title: `Lessons ${isTable?'Table':'Board'}` }]"-->
  <!--    ></CampusHeader>-->
  <!--    <section class="section pt-4">-->
  <div>
    <!--                <title-bar>{{ board_title }}</title-bar>-->
    <div class="columns">
      <div class="column is-12">
        <b-field group-multiline grouped>
          <!--          <b-field>-->
          <!--            <b-select @input="selectSubject" placeholder="Subject" :value="subject_id">-->
          <!--              <option v-for="subject of subjects" :key="subject.id" :value="subject.id">-->
          <!--                {{ subject.id }} {{ subject.long_name }}-->
          <!--              </option>-->
          <!--            </b-select>-->
          <!--          </b-field>-->
          <!--          <b-field>-->
          <!--            <b-select placeholder="Class" v-model="class_group_id">-->
          <!--              <option :value="null">All</option>-->
          <!--              <option v-for="classGroup of classGroups" :key="classGroup.id" :value="classGroup.id">-->
          <!--                {{ classGroup.name }}-->
          <!--              </option>-->
          <!--            </b-select>-->
          <!--          </b-field>-->
          <b-input v-model="lessonSearch" v-debounce:300ms="getFilteredLessons" expanded placeholder="Search"
          ></b-input>
          <b-select v-model="status_id" expanded placeholder="Lesson Status">
            <option :value="null">All</option>
            <!--                  <option :value="3">Unplanned</option>-->
            <option :value="1">Planned</option>
            <option :value="2">Completed</option>

          </b-select>

          <p class="control">
            <b-button
                :disabled="!isTodayEnabled"
                @click="dateForWeek=new Date()">
              Today
            </b-button>
          </p>
          <p class="control is-expanded">
            <DatePicker :date="dateForWeek"
                        :display-type="'WeekPicker'"
                        :type="'light'"
                        @back="weekBack"
                        @forward="weekForward"
                        @set="val=>dateForWeek=val"></DatePicker>
          </p>

        </b-field>


      </div>
      <!--          <div class="column is-2">-->
      <!--            <b-switch v-model="isTable" :true-value="true" :false-value="false">Table View</b-switch>-->
      <!--          </div>-->
    </div>

    <!--        <b-table-->
    <!--            v-if="isTable && isLoaded"-->
    <!--            ref="table"-->
    <!--            :scrollable="true"-->
    <!--            class="margin-top"-->
    <!--            :data="tableHold"-->
    <!--            :striped="true"-->
    <!--            :hoverable="true"-->
    <!--            :bordered="true"-->
    <!--            narrowed-->
    <!--            backend-sorting-->
    <!--            @sort="onSort"-->
    <!--        >-->
    <!--          <template #empty>-->
    <!--            <div class="has-text-centered">No applications</div>-->
    <!--          </template>-->
    <!--          <b-table-column v-slot="props"-->
    <!--                          label="Won/Lost"-->
    <!--                          field="status_id"-->
    <!--          >{{ (props.row.victory_status_id == 3) ? 'Lost' : (props.row.victory_status_id == 2) ? 'Won' : 'Open' }}-->
    <!--          </b-table-column>-->
    <!--          <b-table-column label="First name" v-slot="props"-->
    <!--                          field="user.first_name">{{ props.row.user.first_name }}-->
    <!--          </b-table-column>-->
    <!--          <b-table-column v-slot="props"-->
    <!--                          label="Last name" field="user.last_name">{{ props.row.user.last_name }}-->
    <!--          </b-table-column>-->
    <!--          <b-table-column v-slot="props"-->

    <!--                          label="Owner"-->
    <!--                          field="owner.first_name"-->
    <!--          >{{ props.row.owner != null ? props.row.owner.first_name : '' }}-->
    <!--            {{ props.row.owner != null ? props.row.owner.last_name : '' }}-->
    <!--          </b-table-column>-->
    <!--          <b-table-column v-slot="props"-->

    <!--                          label="Creator"-->
    <!--                          field="creator.first_name"-->
    <!--          >{{ props.row.creator != null ? props.row.creator.first_name : '' }}-->
    <!--            {{ props.row.creator != null ? props.row.creator.last_name : '' }}-->
    <!--          </b-table-column>-->
    <!--          <b-table-column v-slot="props"-->
    <!--                          label="Application Stage" field="application_stage_id">{{ props.row.stage!==null? props.row.stage.name: 'Deleted Stage' }}-->
    <!--          </b-table-column>-->
    <!--          &lt;!&ndash;        <b-table-column&ndash;&gt;-->
    <!--          &lt;!&ndash;            :label="$tc('Course',1)"&ndash;&gt;-->
    <!--          &lt;!&ndash;            field="course.name"&ndash;&gt;-->
    <!--          &lt;!&ndash;        >{{ props.row.course != null ? props.row.course.name : '' }}&ndash;&gt;-->
    <!--          &lt;!&ndash;        </b-table-column>&ndash;&gt;-->
    <!--          <b-table-column v-slot="props"-->
    <!--                          sortable-->
    <!--                          label="Priority" field="priority">{{ props.row.priority }}-->
    <!--          </b-table-column>-->
    <!--          <b-table-column v-slot="props"-->
    <!--                          label="Age" field="age_human_readable">{{ props.row.age_human_readable }}-->
    <!--          </b-table-column>-->
    <!--          <b-table-column v-slot="props"-->
    <!--                          label="Year and Month">{{ formatApplicationDate(props.row) }}-->
    <!--          </b-table-column>-->
    <!--          <b-table-column v-slot="props"-->

    <!--                          sortable-->
    <!--                          field="created_at"-->
    <!--                          label="Created at"-->
    <!--          >{{ props.row.created_at != null ? (new Date(props.row.created_at).toLocaleString()) : '' }}-->
    <!--          </b-table-column>-->
    <!--          <b-table-column v-slot="props"-->

    <!--                          sortable-->
    <!--                          field="updated_at"-->
    <!--                          label="Updated at"-->
    <!--          >{{ props.row.updated_at != null ? (new Date(props.row.updated_at).toLocaleString()) : '' }}-->
    <!--          </b-table-column>-->
    <!--          <b-table-column v-slot="props"-->
    <!--                          custom-key="actions" label="View">-->
    <!--            <b-button tag="router-link" :to="`/applications/edit/${props.row.id}`"-->
    <!--                      @click="goToApplication(props.row.id)" type="is-primary">View-->
    <!--            </b-button>-->
    <!--          </b-table-column>-->
    <!--        </b-table>-->
    <!--        <b-pagination-->
    <!--            v-if="isTable && isLoaded"-->
    <!--            class="margin-top"-->
    <!--            :total="meta.total"-->
    <!--            :current="page"-->
    <!--            :range-before="2"-->
    <!--            :range-after="2"-->
    <!--            :per-page="page_limit"-->
    <!--            aria-next-label="Next page"-->
    <!--            aria-previous-label="Previous page"-->
    <!--            aria-page-label="Page"-->
    <!--            aria-current-label="Current page"-->
    <!--            v-on:change="setPage"-->
    <!--        ></b-pagination>-->
    <div class="bar-container ">
      <PerfectScrollbarWrapper v-if="value && !isTable" class="stage-columns">

        <div class="columns flippy">
          <div v-for="column in columnSortedLessons" :key="column.name" class="column stage-column">
            <h3 class="has-text-centered" style="min-height: 3rem">{{ column.name }}</h3>
            <draggable
                v-model="column.lessons"
                :class="{'column-target':dragging && draggingColumn !== column.name}"
                :disabled="!$store.getters['entities/user-permissions/find']('edit applications')"
                :force-fallback="true"
                :group="{name:column.name, pull:true,put:true}"
                class="column-view"
                @change="onChange($event, column)"
                @end="endDrag($event,column.name)"
                @start="startDrag($event,column.name)"
            >
              <div
                  v-for="lesson in column.lessons"
                  :id="lesson.id"
                  :key="lesson.id"
                  :class="{'application-dragged':dragging&& draggingLesson === lesson.id,'priority-5':lesson.status_id===3,'priority-3':lesson.status_id===1,'priority-2':lesson.status_id===2}"
                  class="box small-box has-pointer"
                  @click="openLesson(lesson.id)"
              >
                <span class="has-text-grey-dark">{{ lesson.lesson_plan.name }}</span>
                <!--              <br/>-->
                <!--              <span v-if="application.course">{{ application.course.code }}</span>-->
                <br/>


              </div>
              <hr :class="{'mt-0':column.lessons.length<1}"/>
            </draggable>
          </div>
        </div>
      </PerfectScrollbarWrapper>
    </div>

    <hr/>
    <!--        <infinite-loading-->
    <!--            :identifier="infiniteId"-->
    <!--            spinner="waveDots"-->
    <!--            v-if="value && !isTable"-->
    <!--            @infinite="infiniteHandler"-->
    <!--        ></infinite-loading>-->
  </div>
  <!--    </section>-->
  <!--  </section>-->
</template>

<script>
/* eslint-disable no-prototype-builtins */

import User from "@/models/User";

import Application from "@/models/Application";
// import InfiniteLoading from "vue-infinite-loading";
import draggable from "vuedraggable";
import {addDays, addWeeks, format, isSameDay, isSameWeek, startOfWeek, subWeeks} from 'date-fns'
import Lesson from "@/models/Lesson";
import Subject from "@/models/Subject";
import ClassGroup from "@/models/ClassGroup";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";
import DatePicker from "@/components/dates/DatePicker";
import LessonForm from "@/components/lessons/LessonForm";
import {mapState} from "vuex";
import PreviousLessonsForm from "@/components/lessons/PreviousLessonsForm";

export default {
  name: "Lessons",
  components: {
    // TitleBar,
    // InfiniteLoading,
    draggable,
    // CampusHeader,
    PerfectScrollbarWrapper,
    DatePicker
  },
  props: {
    defaultClassGroupId: {
      type: Number,
      default() {
        return null
      }
    }, reloadBitFlip: {
      type: Boolean,
      default() {
        return false
      }
    },
    board_title: {
      type: String, required: true
    }, subject_id: {
      type: Number, required: true
    },

  },
  data() {
    return {
      isLoaded: false,
      tableHold: [],
      infiniteId: new Date(),
      isTable: false,
      lessonSearch: null,
      search: null,
      draggingColumn: null,
      draggingLesson: null,
      dragging: false,
      showWonLost: false,
      filter: "",
      statusFilter: null,
      yearFilter: this.default_year,
      semesterFilter: null,
      rplCatFilter: 0,
      course_scope_id: null,
      status_id: null,
      value: false,
      noOwner: false,
      // noCourse: false,
      learnerSearchTerm: "",
      ownerSearchTerm: "",
      // courseSearchTerm: "",
      learnerSearchId: null,
      // courseSearchId: null,
      ownerSearchId: null,
      guardianSearchId: null,
      phaseSearchId: null,
      stageSearchId: null,
      learnerSearchResponse: [],
      // courseSearchResponse: [],
      ownerSearchResponse: [],
      columnSortedLessons: [],
      order_by: 'created_at',
      order_direction: 'desc',
      card_page: 1,
      card_page_limit: 10,
      page: 1,
      page_limit: 50,
      meta: Object,
      dateForWeek: new Date()
    };
  },

  async mounted() {
    this.$store.dispatch("loader/show");
    // await this.fetchSubjects()

    await Lesson.PreviousPlannedCount({subject_id: this.subject_id}).then(({response: {data: {count}}}) => {
      if (count > 0) {
        this.startProcessPrevious(count)
      }
    })
    let lessons_save = this.$cookies.get("lessons_save");
    if (lessons_save != null) {
      this.page = lessons_save.page;

      // if (Object.prototype.hasOwnProperty.call(lessons_save.filters, 'subject_id')) {
      //   this.subject_id = lessons_save.filters.subject_id;
      // }
      if (Object.prototype.hasOwnProperty.call(lessons_save.filters, 'status_id')) {
        this.status_id = lessons_save.filters.status_id;
      }
      // if (Object.prototype.hasOwnProperty.call(lessons_save.filters, 'class_group_id')) {
      //   this.class_group_id = lessons_save.filters.class_group_id;
      // }
      if (Object.prototype.hasOwnProperty.call(lessons_save.filters, 'dateForWeek')) {
        this.dateForWeek = new Date(lessons_save.filters.dateForWeek);
      }
      this.isTable = lessons_save.isTable;
      this.$cookies.remove("lessons_save");
      await this.selectSubject(this.subject_id)

      this.$store.dispatch("loader/hide");

    } else {
      await this.selectSubject(this.subject_id)
      this.getLessons(
          this.card_page,
          this.card_page_limit,
          this.filters,
          // !this.isTable
      ).then(() => {
        this.$store.dispatch("loader/hide");
      });
    }
  },
  watch: {
    reloadBitFlip(newVal) {
      if (newVal) {
        this.$store.dispatch('loader/show')
        this.getLessons(
            this.card_page,
            this.card_page_limit,
            this.filters,
            // !this.isTable
        ).then(() => {
          this.$store.dispatch("loader/hide");
          this.$emit('flipped')
          this.$forceUpdate();
        })
      }
    },
    isTable() {
      this.$store.dispatch("loader/show");

      Application.deleteAll();
      this.getApplications(
          this.page,
          this.page_limit,
          this.filters,
          // !this.isTable
      ).then(() => {
        this.isLoaded = true;
        this.tableHold = this.applications;
        this.$store.dispatch("loader/hide");
      });
    },
    filters() {
      // if (this.isTable && this.isLoaded) {
      //   this.$store.dispatch("loader/show");
      //   this.page = 1;
      //
      //   Application.deleteAll();
      //   this.getApplications(
      //       this.page,
      //       this.page_limit,
      //       this.filters,
      //       // !this.isTable
      //   ).then(() => {
      //     this.tableHold = this.applications;
      //
      //     this.$store.dispatch("loader/hide");
      //   });
      // }
      // if (!this.isTable) {
      this.card_page = 1;
      this.infiniteId++;
      this.$store.dispatch("loader/show");
      console.log('b')
      Lesson.deleteAll();
      this.getLessons(
          this.card_page,
          this.card_page_limit,
          this.filters,
          // !this.isTable
      ).then(() => {
        this.$store.dispatch("loader/hide");
        this.$forceUpdate();
      });
      // }
    },
  },
  methods: {
    getFilteredLessons(text) {
      this.search = text
    },
    startProcessPrevious(count) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          count: count,
          subject_id: this.subject_id
        },
        component: PreviousLessonsForm,
        fullScreen: false,
        hasModalCard: true,
        trapFocus: true,
        canCancel: ['outside', 'button', 'x'],
        events: {
          "submitted": () => {
            this.$store.dispatch('loader/show')
            this.getLessons(
                this.card_page,
                this.card_page_limit,
                this.filters,
                // !this.isTable
            ).then(() => {
              this.$store.dispatch("loader/hide");
              this.$forceUpdate();
            })
          },
        },
      });
    },
    weekForward() {
      this.dateForWeek = addWeeks(this.dateForWeek, 1)
    }, weekBack() {
      this.dateForWeek = subWeeks(this.dateForWeek, 1)
    },
    selectSubject(subject_id) {
      // this.subject_id = subject_id
      // this.course_scope_id = Subject.find(subject_id).course_scope_id
      return ClassGroup.FetchAll({page: 1, limit: 999}, {subject_id: subject_id})
    },
    fetchSubjects() {
      return Subject.FetchAll({page: 1, limit: 999}, {campus_id: this.$store.state.campus.selected_campus_id})
    },
    togglePriority() {
      if (this.order_by === 'priority') {
        this.order_by = 'created_at'
        this.order_direction = 'desc'
        // this.setPage(1)
      } else {
        this.order_by = 'priority'
        this.order_direction = 'desc'
        // this.setPage(1)
      }
    }, toggleCreated() {
      if (this.order_by === 'created_at') {
        this.order_direction = this.order_direction === 'desc' ? 'asc' : 'desc'
        // this.setPage(1)
      } else {
        this.order_by = 'created_at'
        this.order_direction = 'desc'
        // this.setPage(1)
      }
    },


    onSort(column, sort) {
      this.order_by = column;
      this.order_direction = sort;
      this.setPage(this.page);
    },
    setPage(pageNumber) {
      this.$store.dispatch("loader/show");
      Application.deleteAll();
      this.page = pageNumber;
      this.getApplications(
          this.page,
          this.page_limit,
          this.filters
          // !this.isTable
      ).then(() => {
        this.tableHold = this.applications;
        this.$store.dispatch("loader/hide");
      });
    },

    startDrag(evt, columnName) {
      this.dragging = true;
      this.draggingColumn = columnName;
      /**
       * @param evt          The drag event.
       * @param evt.originalEvent   Contains the element of the dragged column
       */
      this.draggingLesson = evt.originalEvent.target.id;
    },
    endDrag() {
      this.dragging = false;
      this.draggingColumn = null;
      this.draggingLesson = null;
    },
    setNoOwner() {
      this.ownerSearchId = null;
      this.noOwner = true;
    },
    setNoCourse() {
      this.courseSearchId = null;
      this.noCourse = true;
    },
    twiddle() {
      this.setSortedApplications();
      this.$forceUpdate();
    },
    selectCourse(option) {
      this.courseSearchId = option.id;
      this.courseSearchResponse = [];
    },
    selectOwner(option) {
      this.noOwner = false;
      this.ownerSearchId = option.id;
      this.ownerSearchResponse = [];
    }, selectPhase(option) {
      this.phaseSearchId = option.id;
    }, selectStage(option) {
      this.stageSearchId = option.id;
    },
    selectLearner(option) {
      this.learnerSearchId = option.id;
    }, selectGuardian(option) {
      this.guardianSearchId = option.id;
    },
    getFilteredOwner(text) {
      this.ownerSearchResponse = User.query()
          .search(text, {
            caseSensitive: false,
            threshold: 0.3,
          })
          .get();
    },

    // getFilteredCourse(text) {
    //   this.loadingCourses = true;

    // Course.search(text, true).then((result) => {
    //   this.loadingCourses = false;
    //   if (result.entities) {
    //     this.courseSearchResponse = result.entities.courses;
    //   } else {
    //     this.courseSearchResponse = [];
    //   }
    // });
    // },

    openLesson(id) {
      this.$cookies.set(
          "lessons_save",
          {
            filters: this.filters,
            page: this.page,
            isTable: this.isTable,
            route: this.$router.currentRoute.path
          }
      );
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          lesson: Lesson.query().withAll().whereId(id).first(),
          subject_id: this.subject_id
        },
        component: LessonForm,
        hasModalCard: false,
        trapFocus: true,
        events: {
          "submitted": () => {
            this.$store.dispatch('loader/show')
            this.getLessons(
                this.card_page,
                this.card_page_limit,
                this.filters,
                // !this.isTable
            ).then(() => {
              this.$store.dispatch("loader/hide");
              this.$forceUpdate();
            })
          },
        },
      });

    },

    setSortedLessons() {
      this.columns.forEach((element, index) => {

        this.columnSortedLessons[index] = {
          date: element.date,
          name: element.name,
          lessons: Lesson.query()
              .where("date", (value) => {
                if (element.date === null) {
                  return value === null
                }
                return isSameDay(new Date(element.date), new Date(value))
              })
              .with('lesson_plan')
              .get()
        };
      });
      this.value = true;
    },
    onChange(evt, column) {
      if (evt.hasOwnProperty("added")) {
        let lesson = evt.added.element;
        lesson.status_id = column.name === 'Unplanned' ? 3 : lesson.status_id
        Lesson.update({
          where: lesson.id,
          data: {
            date: column.name === 'Unplanned' ? null : format(new Date(column.date), 'yyyy-MM-dd'),
            status_id: column.name === 'Unplanned' ? 3 : lesson.status_id
          },
        }).then((result) => {
          Lesson.Update(result);
        });
      }
    },
    //
    // infiniteHandler($state) {
    //   return Application.FetchAll(
    //       {
    //         page: this.card_page,
    //         limit: this.card_page_limit,
    //       },
    //       {
    //
    //         ...this.filters,
    //         ...(this.order_by
    //             ? {
    //               order_by: this.order_by,
    //             }
    //             : {order_by: "created_at"}),
    //         ...(this.order_direction
    //             ? {
    //               order_direction: this.order_direction,
    //             }
    //             : {order_direction: "desc"}),
    //       },
    //       ['user', 'stage', 'phase']
    //   ).then(({response}) => {
    //     if (response.data.data.length > 0) {
    //       this.meta = response.data.meta;
    //       this.card_page++;
    //       this.twiddle();
    //       $state.loaded();
    //       return;
    //     }
    //     $state.complete();
    //   });
    // },
    getLessons(page, limit, query) {
      let unplannedFilters = JSON.parse(JSON.stringify(query))
      delete unplannedFilters.dateForWeek
      unplannedFilters.status_id = 3
      return Promise.all([Lesson.FetchAll({page: page, limit: limit},
          {
            ...query,

          }, ['lesson_plan'],
      ), Lesson.FetchAll({page: page, limit: limit},
          {
            ...unplannedFilters,

          }, ['lesson_plan'],
      )]).then(() => {
        // if (result.response.data.meta) {
        //   this.meta = result.response.data.meta;
        // }
        this.$cookies.set(
            "lessons_save",
            {
              filters: this.filters, page: this.page,
              isTable: this.isTable,
              route: this.$router.currentRoute.path
            }
        );
        this.setSortedLessons();
      });
    },
  },

  computed: {
    ...mapState('campus', ['selected_campus_id']),
    isTodayEnabled() {
      return !isSameWeek(new Date(this.dateForWeek), new Date())
    },
    lessons() {
      return Lesson.query().with('lesson_plan').get()
    },
    classGroups() {
      return ClassGroup.query().where('subject_id', this.subject_id).get()
    },
    // subjects() {
    //   return Subject.query().where('campus_id', this.$store.state.campus.selected_campus_id).orderBy('name').get()
    // },
    columns() {
      let columns = [{name: 'Unplanned', date: null}]
      let firstDay = startOfWeek(new Date(this.dateForWeek), {weekStartsOn: 1})
      for (let i = 0; i < 7; i++) {
        let day = addDays(firstDay, i)
        columns.push({name: format(day, 'E, d/L'), date: day})
      }
      return columns
    },
    filters() {
      return {
        ...(this.statusFilter
            ? {
              status_id: this.statusFilter,
            }
            : {}), ...(this.$store.state.campus.selected_campus_id
            ? {
              campus_id: this.$store.state.campus.selected_campus_id,
            }
            : {}),
        ...(this.subject_id
            ? {
              subject_id: this.subject_id,
            }
            : {}), ...(this.search
            ? {
              search: this.search,
            }
            : {}),
        // ...(this.course_scope_id
        //     ? {
        //       course_scope_id: this.course_scope_id,
        //     }
        //     : {}),
        ...(this.defaultClassGroupId
            ? {
              class_group_id: this.defaultClassGroupId,
            }
            : {}), ...(this.status_id
            ? {
              status_id: this.status_id,
            }
            : {}),
        ...(this.dateForWeek
            ? {
              dateForWeek: format(new Date(this.dateForWeek), 'yyyy-MM-dd'),
            }
            : {}),
      };
    },


  },
};
</script>

<style lang="scss" scoped>
.column-view {
  .box {
    padding: 0.5rem;
  }

  height: 100%;
}

a:visited {
  color: unset !important;
}

.stage-columns {
  overflow-x: scroll;
  overflow-y: hidden;

  .column {
    &.stage-column {
      min-width: 175px;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.bar-container,
.flippy {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg); /* Mozilla */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  -ms-transform: rotateX(180deg); /* IE 9+ */
  -o-transform: rotateX(180deg); /* Opera */
}

.application-dragged {
  border: 2px solid lightgreen;
}


.column-target {
  border: 2px solid lightblue;
  border-radius: 6px;
}
</style>
