<template>
  <CardIndex
      :board_title="'Lessons'"
      :stage_order_offset="offset"
      :stage_order_limit="limit"
      :team_permission="'view lesson-plans'"
  />
</template>

<script>

import CardIndex from "@/components/lessons/CardIndex";

export default {
  name: "LessonPlannerCardIndex",
  components: {
    CardIndex
  },
  data() {
    return {
      title: 'Lessons',
      permission: 'view lesson-plans',
      offset: 0,
      limit: 10
    };
  },

};
</script>

