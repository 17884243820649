<template>
  <div>
    <div :class="{'modal-card':inModal}" class="previous-lessons-modal" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">Lessons planned in previous weeks</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <p>You have {{ count }} lesson{{ count > 1 ? 's' : '' }} planned for previous weeks. Where would you like to move
          them?</p>
        <div class="columns is-fullwidth pt-4">
          <div class="column">
            <div class="panel">
              <p class="panel-heading has-text-centered">
                This Week
              </p>
              <div class="panel-block action-content">
                <p>Move them to this week on the same weekday as before.</p>

              </div>


              <div class="panel-block ">
                <div class="has-text-centered is-fullwidth">
                  <b-button type="is-outlined" @click="submit(1)">Move</b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="panel">
              <p class="panel-heading has-text-centered">
                Unplanned Column
              </p>
              <div class="panel-block action-content">
                <p>Move the lessons to the unplanned column.</p>

              </div>


              <div class="panel-block ">
                <div class="has-text-centered is-fullwidth">
                  <b-button type="is-outlined" @click="submit(2)">Move</b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="panel">
              <p class="panel-heading has-text-centered">
                Leave Them
              </p>
              <div class="panel-block action-content">
                <p>You can access them by navigating to previous weeks</p>

              </div>


              <div class="panel-block ">
                <div class="has-text-centered is-fullwidth">
                  <b-button type="is-outlined" @click="submit(3)">Leave</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
    <b-loading v-model="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </div>
</template>

<script>

import Lesson from "@/models/Lesson";

export default {
  name: "FollowupForm",
  data() {
    return {
      loading: false
    }
  },

  computed: {},

  methods: {


    submit(strategy) {
      this.loading = true

      Lesson.ProcessPlanned(strategy, this.subject_id
      ).then(() => {
        this.loading = false
        this.$buefy.snackbar.open(`Lessons Processed!`)
        this.$emit('submitted')
        this.$emit('close')

      }).catch(err => {
        this.loading = false
        this.handleError(err)
      })

    }
  }
  ,
  mounted() {
    if (this.inModal) {
      // document.getElementsByClassName('modal-content')[0].classList.add("is-large");
    }

  }
  ,
  props: {

    count:
        {
          type: Number,
          default
              () {
            return 0
          }
          ,
        }
    , subject_id:
        {
          type: Number,
          default
              () {
            return null
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }

    ,
  }
}
</script>

