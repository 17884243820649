<template>
  <div :class="{'light':type==='light','dark':type==='dark'}"
       class="columns datepicker-container has-text-centered is-multiline  is-vcentered is-mobile is-centered">
    <div class="column is-2">
      <a
          :class="{'has-text-white':type==='dark','dark-text':type==='light','has-text-grey-dark':type==='transparent'}"
          class="card-header-icon px-0 py-0"
          @click="go('back')"
      >
        <b-icon :icon="$tc('icons.chevron-left')" size="is-medium"/>
      </a>

    </div>
    <div @click.prevent="$refs.datepicker.toggle()"
         class="column is-8 has-pointer">
      <div></div>
      <p
          v-if="displayType==='DayPicker'"
          :class="{'has-text-white':type==='dark','dark-text':type==='light','has-text-grey-dark':type==='transparent'}"
          class="has-text-weight-semibold "
      >{{ formattedDate }}</p>
      <p
          v-if="displayType==='WeekPicker'"
          :class="{'has-text-white':type==='dark','dark-text':type==='light','has-text-grey-dark':type==='transparent'}"
          class="has-text-weight-semibold "
      >{{ firstDay }} - {{lastDay}}</p>

    </div>
    <div class="column is-2">
      <a
          :class="{'has-text-white':type==='dark','dark-text':type==='light','has-text-grey-dark':type==='transparent'}"
          class="card-header-icon px-0 py-0"
          @click="go('forward')"
      >
        <b-icon :icon="$tc('icons.chevron-right')" size="is-medium"/>
      </a>
    </div>
    <div class="column px-0 py-0">
      <div class="columns is-centered">
        <div class="column is-offset-3 has-text-centered ">
          <b-field position="is-centered">
            <b-datepicker
                class="programmatic-datepicker-container"
                v-model="datePickerDate"
                ref="datepicker"
                expanded
                placeholder="Select a date">
            </b-datepicker>
          </b-field>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {endOfWeek, format, startOfWeek} from 'date-fns'

export default {
  name: "DatePicker",
  data() {
    return {
      loaded: false,
      showMenu: false
    }
  },

  computed: {
    datePickerDate: {
      set(val) {
        this.$emit('set', val)
      }, get() {
        return this.date
      }
    },
    firstDay() {
      return format(startOfWeek(this.date, {weekStartsOn: 1}), ' iii, dd MMM')
    }, lastDay() {
      return format(endOfWeek(this.date, {weekStartsOn: 1}), ' iii, dd MMM')
    },
    formattedDate() {
      return format(this.date, ' iii, dd MMM')
    }
  },
  methods: {
    go(direction) {
      this.$emit(direction)
    }
  },
  props: {
    type: {
      type: String, default: () => 'dark'
    }, displayType: {
      type: String, default: () => 'DayPicker'
    },
    date: {type: Date, required: true}
  },

}

</script>
