<template>
  <form @submit.prevent="submit()">
    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p v-if="edit" class="modal-card-title">Lesson - {{ lesson.lesson_plan.name }}
          {{ lesson.class_group !== null ? '- ' + lesson.class_group.name : '' }}</p>
        <p v-else class="modal-card-title">New Lesson</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-tabs v-model="activeTab" :destroy-on-hide="true" class="hidden-tabs" expanded multiline>
          <b-tab-item>
            <b-field v-if="edit" label="Planned Date">
              <b-datepicker
                  v-model="lessonDate" :years-range="[-2,2]" expanded placeholder="Lesson Date"
                  required></b-datepicker>
            </b-field>
            <b-field expanded label="Class">
              <b-select v-model="lessonObject.class_group_id"

                        :placeholder="lessonObject.class_group_id===null?'Not Class Specific':'Class'"
                        expanded>
                <option :value="null">Not Class Specific</option>
                <option v-for="classGroup of classGroups" :key="classGroup.id" :value="classGroup.id">
                  {{ classGroup.name }}
                </option>
              </b-select>
            </b-field>
            <b-field expanded label="Lesson Plan">
              <LessonPlansFilter :campus_id="lessonObject.campus_id" :disabled="edit"
                                 :selected_lesson_plan_id="lessonObject.lesson_plan_id"
                                 :subject_id="lessonObject.subject_id"
                                 :type="'autocomplete'"
                                 required
                                 ref="lessonPlanFilter"
                                 @selected="selectLessonPlan"></LessonPlansFilter>
            </b-field>
            <b-field v-if="edit" :label="$tc('Learner',2)">
              <b-taginput
                  ref="goodTagInput"
                  v-model="lessonObject.learners"
                  :allow-new="false"
                  :data="filteredLearners"
                  :disabled="edit"
                  :open-on-focus="true"
                  :placeholder="`Add ${$tc('learner',2)}`"
                  append-to-body
                  autocomplete
                  @typing="text=>searchText=text"
                  @keydown.enter.native.prevent
              >
                <template slot-scope="props">
                  {{ props.option.first_name }} {{ props.option.last_name }}
                </template>
                <template #empty>
                  There are no {{ $tc('learner', 2) }}
                </template>
                <template #selected="props">
                  <b-tag
                      v-for="(tag, index) in props.tags"
                      :key="index"
                      :closable="!edit"
                      @close="$refs.goodTagInput.removeTag(index, $event)">
                    {{ tag.first_name }} {{ tag.last_name }}
                  </b-tag>
                </template>
              </b-taginput>
            </b-field>
            <b-field label="Planning Notes">
              <b-input v-model="lessonObject.note" expanded placeholder="Notes" type="textarea"></b-input>
            </b-field>
          </b-tab-item>
          <b-tab-item>
            <ProgressTable
                :default-subject-id="lessonObject.subject_id"
                :in-lesson="true"
                :lesson-id="lessonObject.id"
                :submit-bit-flip="submitProgressBitFlip"
                @submitted="()=>submitProgressBitFlip=0"

            >

            </ProgressTable>
            <div class="pt-3">
              <p class="has-text-weight-semibold is-size-3">
                Footprints
              </p>
              <FootprintsContainer :model="'lesson'" :model_id="lessonObject.id"
                                   :sync-bit-flip="syncFootprintsBitFlip"
                                   @footprintsSynced="footprintsSubmitted=true"/>
            </div>
          </b-tab-item>
        </b-tabs>
      </section>

      <footer :class="{'modal-card-foot':inModal}">
        <b-field class="mt-3 has-text-centered" expanded grouped position="is-centered">
          <b-field>
            <b-button @click.prevent="$emit('close')">Cancel</b-button>
          </b-field>
          <b-field position="is-centered">
            <p class="control">
              <b-button native-type="submit"
                        type="is-primary"
              >
                {{ activeTab === 1 ? 'Record' : 'Save' }}
              </b-button>
            </p>
            <p v-if="edit" class="control">
              <b-dropdown append-to-body aria-role="list" position="is-top-right">
                <b-button slot="trigger" type="is-primary">
                  <b-icon :icon="$tc('icons.menu-down')"></b-icon>

                </b-button>
                <b-dropdown-item v-if="activeTab===0" @click.native.prevent="startRecord()">
                  <span>Record</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="activeTab===0 && lessonObject.status_id!==1&&lessonObject.status_id!==2"
                                 @click.native.prevent="submit(1,true)">
                  <span>Save and plan</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="activeTab===1"
                                 @click.native.prevent="submit(2,true,true)">
                  <span>Record and plan followup</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="activeTab===0 && lessonObject.status_id!==3"
                                 @click.native.prevent="submit(3,true)">
                  <span>Save and set unplanned</span>
                </b-dropdown-item>

              </b-dropdown>
            </p>

          </b-field>
          <b-field v-if="edit" position="is-centered">
            <p class="control">
              <b-button
                  :icon-right="$tc(`icons.delete`)"
                  type="is-danger"
                  @click.prevent="startDelete()">Unplan
              </b-button>
            </p>
            <p class="control">
              <b-dropdown append-to-body aria-role="list" position="is-top-right">
                <b-button slot="trigger" type="is-danger">
                  <b-icon :icon="$tc('icons.menu-down')"></b-icon>

                </b-button>
                <b-dropdown-item @click.native.prevent="startDelete(true)">
                  <span>Delete</span>
                </b-dropdown-item>

              </b-dropdown>
            </p>
          </b-field>
        </b-field>
      </footer>
    </div>
    <b-loading v-model="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </form>
</template>

<script>

import {format} from "date-fns";

import ClassGroup from "@/models/ClassGroup";
import User from "@/models/User";
import Lesson from "@/models/Lesson";
import ProgressTable from "@/components/progressTracker/ProgressTable";
import UserRecord from "@/models/UserRecord";
import FootprintsContainer from "@/components/lessons/FootprintsContainer";
import FollowupForm from "@/components/lessons/FollowupForm";
import LessonPlansFilter from "@/components/lessonPlans/LessonPlansFilter";

export default {
  name: "LessonForm",
  components: {LessonPlansFilter, ProgressTable, FootprintsContainer},
  data() {
    return {
      loaded: false,
      loading: false,
      created: false,
      activeTab: 0,
      submitProgressBitFlip: 0,
      syncFootprintsBitFlip: 0,
      footprintsSubmitted: false,
      submitOnFootprintsComplete: false,
      searchText: '',
      submitMedia: false,
      loadingClassGroups: false,
      followupLessonId: null,

      lessonObject: {
        learners: [],
        lesson_plan: {},
        lesson_plan_id: null,
        class_group_id: this.class_group_id,
        subject_id: this.subject_id,
        date: null,
        status_id: 3

      }
    }
  },

  computed: {
    footprints() {
      return UserRecord.query().get()
    },
    lessonDate: {
      set(newVal) {
        this.lessonObject.date = format(newVal, 'yyyy-MM-dd')
      },
      get() {
        if (this.lessonObject.date == null) {
          return null;
        }
        return new Date(this.lessonObject.date);
      },
    },
    filteredLearners() {
      if (this.searchText) {
        return User.query().search(this.searchText, {
          caseSensitive: false,
          threshold: 0.3,
          keys: ['first_name', 'last_name'],
          minMatchCharLength: 0
        }).where((user) => {
          return !this.lessonObject.learners.some(learner => user.id === learner.id)
        }).get()
      }
      return User.query().where((user) => {
        return !this.lessonObject.learners.some(learner => user.id === learner.id)
      }).get()
    },
    learners() {
      return User.query().get()
    },
    classGroups() {
      return ClassGroup.query().where('subject_id', this.subject_id).get()
    },

  },

  watch: {
    footprintsSubmitted(newValue) {
      if (newValue === true) {
        this.submit(null, true, false
        )
      }
    }
  },
  methods: {
    selectLessonPlan(lessonPlan) {
      this.lessonObject.lesson_plan_id = lessonPlan.id
    },
    startFollowupPlan() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          lesson: this.lessonObject
        },
        component: FollowupForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'button', 'x'],
        onCancel: () => {

          this.loading = false
        },
        events: {
          "submitted": (id) => {
            this.followupLessonId = id
            this.submit()
          },
        },
      });
    },
    startRecord() {
      this.loading = true
      this.lessonObject.status_id = 2
      this.activeTab = 1
      this.loading = false
    },
    startDelete(permanentlyDelete = false) {
      if (permanentlyDelete) {
        this.$buefy.dialog.confirm({
          title: `Deleting Lesson`,
          confirmText: `Delete Lesson`,
          hasIcon: true,
          type: "is-danger",
          message: `Are you sure you want to delete this lesson permanently? This action cannot be undone`,
          onConfirm: () =>
              Lesson.Delete(this.lessonObject.id,
              )
                  .then(() => {
                    this.$buefy.snackbar.open(`Lesson Deleted!`);
                    this.$emit('submitted')
                    this.$emit('close')
                  })
                  .catch((err) => {
                    this.handleError(err)
                  }),
        });
        return
      }
      this.$buefy.dialog.confirm({
        title: `Unplanning Lesson`,
        confirmText: `Unplan Lesson`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to unplan this lesson?`,
        onConfirm: () =>
            Lesson.Update({
              id: this.lessonObject.id,
              date: null,
              learners: [],
              class_group_id: this.lessonObject.lesson_plan.class_group_id,
              status_id: 3,
              subject_id: this.lesson.subject_id,
              lesson_plan_id: this.lessonObject.lesson_plan_id
            })
                .then(() => {
                  this.$buefy.snackbar.open(`Lesson Unplanned!`);
                  this.$emit('submitted')
                  this.$emit('close')
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    handleStatusChange(value) {

      value === 1 ? this.lessonObject.approved_by_user_id = null : this.lessonObject.approved_by_user_id = this.$store.state.user.id

    },

    submit(status_override = null, close_on_submit = false, plan_followup = false) {
      // if (this.edit || this.created) {
      //   if (this.canEdit && (this.canManage || this.leaveRequest.status_id === 1)) {
      this.loading = true
      let submitObject = JSON.parse(JSON.stringify(this.lessonObject))
      if (this.edit) {
        submitObject.learners = submitObject.learners.map(learner => learner.id)
        if (status_override !== null) {
          submitObject.status_id = status_override
        }
        if (this.activeTab === 1) {
          if (plan_followup) {
            this.startFollowupPlan()
            return;
          }
          submitObject.status_id = 2
          if (!this.footprintsSubmitted) {
            this.submitProgressBitFlip = 1
            if (this.footprints.length > 0) {
              this.syncFootprintsBitFlip = 1
            } else {
              this.footprintsSubmitted = true
            }
            return
          }

        }
        Lesson.Update(submitObject
        ).then(() => {
          // this.submitMedia = true
          this.loading = false
          this.$buefy.snackbar.open(`Lesson updated!`)
          this.$emit('submitted')

          if (close_on_submit) {
            this.$emit('close')
          }
          this.submitProgressBitFlip = 0
          this.syncFootprintsBitFlip = 0
        }).catch(err => {
          this.loading = false
          this.handleError(err)
        })
      } else {
        Lesson.Store(submitObject).then(() => {
          this.loading = false
          this.$buefy.snackbar.open(`Lesson submitted!`)
          this.$emit('submitted')

          this.$emit('close')
        }).catch(err => {
          this.loading = false
          this.handleError(err)
        })
      }
      // } else {
      //   this.$store.dispatch('toast/createToast')
      // }
      // } else {
      // if (this.canCreate) {
      //   this.loading = true
      //   LeaveRequest.Store({
      //     user_id: this.lessonObject.user_id,
      //     type_id: this.lessonObject.type_id,
      //     dates: this.lessonObject.dates,
      //     is_absent: this.lessonObject.is_absent,
      //     approved_by_user_id: this.lessonObject.approved_by_user_id,
      //     status_id: this.lessonObject.status_id
      //
      //
      //   }).then(({entities: {leaveRequests}}) => {
      //     this.loading = false
      //     this.$buefy.snackbar.open(`Leave Request submitted!`)
      //     this.lessonObject.id = leaveRequests[0].id
      //     this.created = true
      //     this.submitMedia = true
      //   }).catch(err => {
      //     this.loading = false
      //     this.handleError(err)
      //   })
      // } else {
      //   this.$store.dispatch('toast/createToast')
      // }
      // }
    }
  }
  ,
  async mounted() {
    this.loading = true
    if (this.edit) {
      await Lesson.FetchById(this.lesson.id, ['lesson_plan', 'lesson_plan.objectives', 'learners'])
      this.lessonObject = JSON.parse(JSON.stringify(Lesson.query().whereId(this.lesson.id).withAll().first()))
      User.FetchLearnersForLesson({page: 1, limit: 999}, [], this.lessonObject.id)

    }
    this.loaded = true
    this.loading = false

  }
  ,
  props: {
    canManage: {
      type: Boolean,
      default() {
        return false
      }
    }, canCreate: {
      type: Boolean,
      default() {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default() {
        return false
      }
    }, canDelete: {
      type: Boolean,
      default() {
        return false
      }
    },
    default_user: {
      type: Number,
      default() {
        return null
      }
    },
    subject_id: {
      required: true,
      type: Number,
      default() {
        return null
      }
    }, class_group_id: {
      type: Number,
      default() {
        return null
      }
    }, campus_id: {
      type: Number,
      default() {
        return null
      }
    },
    lesson:
        {
          type: Object,
          default
              () {
            return {}
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

