<template>
  <div>
<!--    <p class="has-text-weight-semibold is-size-4">-->
<!--      {{ type_name }}-->
<!--    </p>-->
    <b-field :label="$tc('Learner',2)">
      <b-taginput
          ref="goodTagInput"
          v-model="footprintLearners"
          :allow-new="false"
          :data="filteredLearners"
          :open-on-focus="true"
          :placeholder="`Add ${$tc('learner',2)}`"
          append-to-body
          autocomplete

          @add="add"
          @remove="remove"
          @typing="text=>searchText=text"
          @keydown.enter.native.prevent
      >
        <template slot-scope="props">
          {{ props.option.first_name }} {{ props.option.last_name }}
        </template>
        <template #empty>
          There are no {{ $tc('learner', 2) }}
        </template>
        <template #selected="props">
          <b-tag
              v-for="(tag, index) in props.tags"
              :key="index"
              :closable="true"
              @close="$refs.goodTagInput.removeTag(index, $event)">
            {{ tag.first_name }} {{ tag.last_name }}
          </b-tag>
        </template>
      </b-taginput>
    </b-field>
    <b-field label="Comment">
      <b-input v-model="comment" expanded placeholder="Comment"
               type="textarea"></b-input>

    </b-field>
  </div>

</template>

<script>

import UserRecord from "@/models/UserRecord";
import User from "@/models/User";

export default {
  name: "Footprint",
  data() {
    return {
      newFootprintTypeId: null,
      footprintLearners: [],
      comment: null,
      commentTouched: false,
      searchText: '',
      activeFootprintTypeIds: [],
      newFootprints: [],
      newFootprintUserIds: [],
      removeFootprintUserIds: [],
      loading: false
    };
  },
  props: {
    syncBitFlip: {
      type: Number,
      default() {
        return 0
      }
    },
    baseComment: {
      type: String,
      required: true
    }, type_name: {
      type: String,
      required: true
    }, type_id: {
      type: Number,
      required: true
    },
    footprints: {
      type: Array,
      required: true
    },
  },
  watch: {
    syncBitFlip(newValue) {
      if (newValue === 1) {
        this.syncFootprints()
      }
    }
  },
  methods: {
    async syncFootprints() {

      if (this.removeFootprintUserIds.length > 0) {
        await UserRecord.update({
          where: record => {
            return this.removeFootprintUserIds.includes(record.user_id) && record.type_id === this.type_id && record.comment === this.baseComment
          },
          data: {
            to_be_deleted: true
          }
        })
      }
      if (this.newFootprintUserIds.length > 0) {
        await UserRecord.insert({
          data: this.newFootprintUserIds.map(id => {
            return {user_id: id, type_id: this.type_id, comment: this.comment, is_new: true}
          })
        })
      }
      await UserRecord.update({
        where: record => {
          return this.footprintLearnerIds.includes(record.user_id) && record.type_id === this.type_id && record.comment === this.baseComment
        },
        data: {
          comment: this.comment
        }
      })

      console.log(this.comment)
      this.$emit('synced')

    },
    add(event) {
      if (this.removeFootprintUserIds.includes(event.id)) {
        this.removeFootprintUserIds = this.removeFootprintUserIds.filter(id => {
          return id !== event.id
        })
      } else if (!this.newFootprintUserIds.includes(event.id)) {
        this.newFootprintUserIds.push(event.id)
      }
    },
    remove(event) {
      if (this.newFootprintUserIds.includes(event.id)) {
        this.newFootprintUserIds = this.newFootprintUserIds.filter(id => {
          return id !== event.id
        })
      } else if (!this.removeFootprintUserIds.includes(event.id)) {
        this.removeFootprintUserIds.push(event.id)
      }
    },
    createFootprint() {
      let footprints = this.footprintLearners.map(learner => {
        return {
          type_id: this.newFootprintTypeId,
          user_id: learner.id,
          comment: this.newFootprintComment,
          is_new: true
        }
      })
      UserRecord.insert({data: footprints}).then(() => {
        this.newFootprintComment = null
        this.footprintLearners = []
      })
    },

  },
  computed: {
    footprintLearnerIds() {
      return this.footprintLearners.map(learner => learner.id)
    },
    filteredLearners() {
      if (this.searchText) {
        return User.query().search(this.searchText, {
          caseSensitive: false,
          threshold: 0.3,
          keys: ['first_name', 'last_name'],
          minMatchCharLength: 0
        }).where((user) => {
          return !this.footprintLearners.some(learner => user.id === learner.id)
        }).get()
      }
      return User.query().where((user) => {
        return !this.footprintLearners.some(learner => user.id === learner.id)
      }).get()
    },
  },
  mounted() {
    this.comment = JSON.parse(JSON.stringify(this.baseComment))
    this.footprintLearners = JSON.parse(JSON.stringify(this.footprints.map(footprint => footprint.user)))
  }

};
</script>
