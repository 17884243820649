<template>
  <form @submit.prevent="submit()">
    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">Plan followup</p>
      </header>
      <section :class="{'modal-card-body':inModal}">

        <b-field label="Planned Date">
          <b-datepicker
              v-model="lessonDate" :years-range="[-2,2]" expanded placeholder="Lesson Date"
              required></b-datepicker>
        </b-field>
        <b-field expanded label="Class">
          <b-select v-model="newLessonObject.class_group_id" expanded :placeholder="newLessonObject.class_group_id===null?'Not Class Specific':'Class'">
            <option :value="null">Not Class Specific</option>
            <option v-for="classGroup of classGroups" :key="classGroup.id" :value="classGroup.id">
              {{ classGroup.name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Planning Notes">
          <b-input v-model="newLessonObject.note" expanded placeholder="Notes" type="textarea"></b-input>
        </b-field>
      </section>

      <footer :class="{'modal-card-foot':inModal}">
        <b-field class="mt-3 has-text-centered" expanded grouped position="is-centered">
          <b-field>
            <b-button @click.prevent="$emit('close')">Cancel</b-button>
          </b-field>
          <b-field position="is-centered">
            <p class="control">
              <b-button native-type="submit"
                        type="is-primary"
              >
                Submit
              </b-button>
            </p>

          </b-field>
        </b-field>
      </footer>
    </div>
    <b-loading v-model="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </form>
</template>

<script>

import {format} from "date-fns";

import ClassGroup from "@/models/ClassGroup";
import Lesson from "@/models/Lesson";

export default {
  name: "FollowupForm",
  data() {
    return {
      loaded: false,
      loading: false,
      created: false,
      loadingClassGroups: false,

      newLessonObject: {
        lesson_plan: {}
      }
    }
  },

  computed: {

    lessonDate: {
      set(newVal) {
        this.newLessonObject.date = format(newVal, 'yyyy-MM-dd')
      },
      get() {
        if (this.newLessonObject.date == null) {
          return null;
        }
        return new Date(this.newLessonObject.date);
      },
    },
    classGroups() {
      return ClassGroup.query().where('subject_id', this.newLessonObject.subject_id).get()
    },

  },

  methods: {


    submit() {
      this.loading = true
      let submitObject = {
        status_id: 1,
        note: this.newLessonObject.note,
        date: this.newLessonObject.date,
        subject_id: this.newLessonObject.subject_id,
        lesson_plan_id: this.newLessonObject.lesson_plan_id,
        class_group_id: this.newLessonObject.class_group_id,
      }
      Lesson.Store(submitObject
      ).then(({entities: {lessons}}) => {
        this.loading = false
        this.$buefy.snackbar.open(`Followup Created!`)
        this.$emit('submitted',lessons[0].id)
        this.$emit('close')

      }).catch(err => {
        this.loading = false
        this.handleError(err)
      })

    }
  }
  ,
  mounted() {
    if (this.inModal) {
      this.makeModalLarge()
    }
    this.newLessonObject = JSON.parse(JSON.stringify(this.lesson))
    this.loaded = true
  }
  ,
  props: {

    lesson:
        {
          type: Object,
          default
              () {
            return {}
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

