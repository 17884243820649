<template>
  <div>
    <b-field grouped label="New Footprint Type">
      <b-select v-model="newFootprintTypeId" expanded placeholder="New Footprint Type">
        <optgroup v-for="(type,index) of footprintTypes" :key="index" :label="type.name">
          <option v-for="subType of type.subTypes" :key="subType.id" :value="subType.id">{{ subType.name }}</option>
        </optgroup>
      </b-select>

    </b-field>
    <b-field :label="$tc('Learner',2)">
      <b-taginput
          ref="goodTagInput"
          v-model="newFootprintLearners"
          :allow-new="false"
          :data="filteredLearners"
          :disabled="newFootprintTypeId===null"
          :open-on-focus="true"
          :placeholder="`Add ${$tc('learner',2)}`"
          append-to-body
          autocomplete

          @typing="text=>searchText=text"
          @keydown.enter.native.prevent
      >
        <template slot-scope="props">
          {{ props.option.first_name }} {{ props.option.last_name }}
        </template>
        <template #empty>
          There are no {{ $tc('learner', 2) }}
        </template>
        <template #selected="props">
          <b-tag
              v-for="(tag, index) in props.tags"
              :key="index"
              @close="$refs.goodTagInput.removeTag(index, $event)">
            {{ tag.first_name }} {{ tag.last_name }}
          </b-tag>
        </template>
      </b-taginput>
    </b-field>
    <b-field label="Comment">
      <b-input v-model="newFootprintComment" :disabled="newFootprintTypeId===null" expanded placeholder="Comment"
               type="textarea"></b-input>

    </b-field>
    <b-field>
      <b-button :disabled="newFootprintTypeId===null||newFootprintLearners.length===0||!newFootprintComment"
                :icon-right="$tc('icons.create')"
                type="is-primary"
                @click="createFootprint()"> Add

      </b-button>
    </b-field>
    <div v-if="commentGroupedAcademicFootprints.length>0">
      <p class="has-text-weight-semibold is-size-3">
        Academic
      </p>
      <FootprintGroup v-for="(group) in commentGroupedAcademicFootprints"
                      :key="group.id" :footprint-group="group" :sync-bit-flip="syncGroupsBitFlip"
                      @groupSynced="checkGroupSync()"></FootprintGroup>
    </div>
    <div v-if="commentGroupedSocialFootprints.length>0">
      <p class="has-text-weight-semibold is-size-3">
        Social
      </p>
      <FootprintGroup v-for="(group) in commentGroupedSocialFootprints"
                      :key="group.id" :footprint-group="group" :sync-bit-flip="syncGroupsBitFlip"
                      @groupSynced="checkGroupSync()"></FootprintGroup>
    </div>
    <div v-if="commentGroupedEmotionalFootprints.length>0">
      <p class="has-text-weight-semibold is-size-3">
        Emotional
      </p>
      <FootprintGroup v-for="(group) in commentGroupedEmotionalFootprints"
                      :key="group.id" :footprint-group="group" :sync-bit-flip="syncGroupsBitFlip"
                      @groupSynced="checkGroupSync()"></FootprintGroup>
    </div>


    <!--    <b-dropdown-->

    <!--        v-model="activeFootprintTypeIds"-->
    <!--        scrollable-->
    <!--        max-height="300"-->
    <!--        multiple-->
    <!--        append-to-body-->
    <!--        aria-role="list"-->
    <!--    >-->
    <!--      <b-button type="is-primary"-->
    <!--                expanded-->
    <!--                :icon-right="'chevron-down'"-->
    <!--                slot="trigger"-->
    <!--      >-->
    <!--        <span>Select Footprint Types</span>-->

    <!--      </b-button>-->

    <!--      <span v-for="(type,index) of footprintTypes"-->
    <!--                       :key="index"-->
    <!--      >-->
    <!--        <span class="has-text-weight-semibold pl-3">{{ type.name }}</span>-->
    <!--        <b-dropdown-item v-for="subType of type.subTypes"-->
    <!--                         :key="subType.id"-->
    <!--                         :value="subType.id">-->
    <!--          {{ subType.name }}-->
    <!--        </b-dropdown-item>-->
    <!--      </span> -->

    <!--    </b-dropdown>-->

    <b-loading v-model="loading" :can-cancel="false" :is-full-page="false"></b-loading>

  </div>

</template>

<script>

import UserRecord from "@/models/UserRecord";
import User from "@/models/User";
import UserRecordType from "@/models/UserRecordType";
import FootprintGroup from "@/components/lessons/FootprintGroup";

export default {
  name: "FootprintsContainer",
  components: {FootprintGroup},
  data() {
    return {
      newFootprintTypeId: null,
      newFootprintLearners: [],
      newFootprintComment: null,
      groupSyncCount: 0,
      lockedGroupSyncsNeeded: 0,
      syncGroupsBitFlip: 0,
      searchText: '',
      activeFootprintTypeIds: [],
      footprintTypes: [
        {
          name: 'Academic',
          subTypes: [
            {name: 'AcademicConcern', id: 1, groupName: 'Academic'},
            {name: 'AcademicAchievement', id: 2, groupName: 'Academic'},
            {name: 'AcademicProgress', id: 3, groupName: 'Academic'},
            {name: 'AcademicIntervention', id: 4, groupName: 'Academic'},
            {name: 'WorkEthic', id: 5, groupName: 'Academic'},
            {name: 'WorkHabit', id: 6, groupName: 'Academic'},
            {name: 'StudyHabit', id: 7, groupName: 'Academic'},
            {name: 'Concentration', id: 8, groupName: 'Academic'},
            {name: 'Concentration', id: 9, groupName: 'Academic'},
          ]
        },
        {
          name: 'Social',
          subTypes: [
            {name: 'Participation', id: 10, groupName: 'Social'},
            {name: 'Collaboration', id: 11, groupName: 'Social'},
            {name: 'Articulation', id: 12, groupName: 'Social'},
            {name: 'Respect', id: 13, groupName: 'Social'},
            {name: 'GraceAndCourtesy', id: 14, groupName: 'Social'},
            {name: 'Timekeeping', id: 15, groupName: 'Social'},
            {name: 'Cooperation', id: 16, groupName: 'Social'},
            {name: 'SocialEngagement', id: 17, groupName: 'Social'},
            {name: 'BehaviouralConcern', id: 18, groupName: 'Social'},
            {name: 'SocialConfidence', id: 19, groupName: 'Social'},
            {name: 'SocialIndependence', id: 20, groupName: 'Social'},
          ]
        },
        {
          name: 'Emotional',
          subTypes: [
            {name: 'EmotionalOutbursts', id: 21, groupName: 'Emotional'},
            {name: 'PhysicalOutbursts', id: 22, groupName: 'Emotional'},
            {name: 'Empathy', id: 23, groupName: 'Emotional'},
            {name: 'EmotionalConfidence', id: 24, groupName: 'Emotional'},
            {name: 'EmotionalIndependence', id: 25, groupName: 'Emotional'},
            {name: 'EmotionalRegulation', id: 26, groupName: 'Emotional'},
            {name: 'EmotionalDysregulation', id: 27, groupName: 'Emotional'},

          ]
        },
      ],
      ungroupedFootprintTypes:
          [

            {name: 'AcademicConcern', id: 1, groupName: 'Academic'},
            {name: 'AcademicAchievement', id: 2, groupName: 'Academic'},
            {name: 'AcademicProgress', id: 3, groupName: 'Academic'},
            {name: 'AcademicIntervention', id: 4, groupName: 'Academic'},
            {name: 'WorkEthic', id: 5, groupName: 'Academic'},
            {name: 'WorkHabit', id: 6, groupName: 'Academic'},
            {name: 'StudyHabit', id: 7, groupName: 'Academic'},
            {name: 'Concentration', id: 8, groupName: 'Academic'},
            {name: 'Concentration', id: 9, groupName: 'Academic'},

            {name: 'Participation', id: 10, groupName: 'Social'},
            {name: 'Collaboration', id: 11, groupName: 'Social'},
            {name: 'Articulation', id: 12, groupName: 'Social'},
            {name: 'Respect', id: 13, groupName: 'Social'},
            {name: 'GraceAndCourtesy', id: 14, groupName: 'Social'},
            {name: 'Timekeeping', id: 15, groupName: 'Social'},
            {name: 'Cooperation', id: 16, groupName: 'Social'},
            {name: 'SocialEngagement', id: 17, groupName: 'Social'},
            {name: 'BehaviouralConcern', id: 18, groupName: 'Social'},
            {name: 'SocialConfidence', id: 19, groupName: 'Social'},
            {name: 'SocialIndependence', id: 20, groupName: 'Social'},

            {name: 'EmotionalOutbursts', id: 21, groupName: 'Emotional'},
            {name: 'PhysicalOutbursts', id: 22, groupName: 'Emotional'},
            {name: 'Empathy', id: 23, groupName: 'Emotional'},
            {name: 'EmotionalConfidence', id: 24, groupName: 'Emotional'},
            {name: 'EmotionalIndependence', id: 25, groupName: 'Emotional'},
            {name: 'EmotionalRegulation', id: 26, groupName: 'Emotional'},
            {name: 'EmotionalDysregulation', id: 27, groupName: 'Emotional'},

          ],

      loading: false
    };
  },
  props: {

    model: {
      type: String,
      default() {
        return null
      }
    },
    model_id: {
      type: Number,
      default() {
        return null
      }
    },
    syncBitFlip: {
      type: Number,
      default() {
        return 0
      }
    }


  },
  watch: {
    syncBitFlip(newValue) {
      if (newValue === 1) {
        this.lockedGroupSyncsNeeded = this.commentGroupedAcademicFootprints.length + this.commentGroupedSocialFootprints.length + this.commentGroupedEmotionalFootprints.length
        this.syncGroupsBitFlip = 1
      }
    }
  },
  methods: {
    checkGroupSync() {
      this.groupSyncCount++
      console.log('groupSync')
        if (this.groupSyncCount === this.lockedGroupSyncsNeeded) {
          let promises = []
          console.log(JSON.parse(JSON.stringify(this.deleteFootprints.length)), JSON.parse(JSON.stringify(this.newFootprints.length)), JSON.parse(JSON.stringify(this.updateFootprints.length)))
          if (this.deleteFootprints.length > 0) {
            promises.push(UserRecord.DeleteBulk(this.deleteFootprints.map(footprint => footprint.id), this.model, this.model_id))
          }
          if (this.newFootprints.length > 0) {
            promises.push(UserRecord.StoreBulk(this.newFootprints, this.model, this.model_id))
          }
          if (this.updateFootprints.length > 0) {
            promises.push(UserRecord.UpdateBulk(this.updateFootprints, this.model, this.model_id))
          }

          Promise.all(promises).then(() => {
            this.$emit('footprintsSynced')

          }).catch(err => {
            this.handleError(err)
          })

        }


    },
    createFootprint() {
      let footprints = this.newFootprintLearners.map(learner => {
        return {
          type_id: this.newFootprintTypeId,
          user_id: learner.id,
          comment: this.newFootprintComment,
          is_new: true,
          admin_only: false,
        }
      })
      UserRecord.insert({data: footprints}).then(() => {
        this.newFootprintComment = null
        this.newFootprintLearners = []
      })
    },

  },
  computed: {
    commentGroupedAcademicFootprints() {
      let items = []
      let types = UserRecordType.query().where('groupName', 'Academic').orderBy('id').get()
      types.forEach(type => {
        if (UserRecord.query().where('type_id', type.id).exists()) {
          items.push({
            footprints: UserRecord.query().where('type_id', type.id).get(),
            comments: [],
            comment_grouped: {},
            type_id: type.id,
            type_name: type.name
          })

        }
      })
      items.map(item => {
        item.footprints.map(footprint => {
          if (!item.comments.includes(footprint.comment)) {
            item.comments.push(footprint.comment)
          }
        })
        item.comments.map((comment, index) => {
          item.comment_grouped[index] = UserRecord.query().where('type_id', item.type_id).where('comment', comment).withAll().get()
        })
        item.id = item.footprints[0].id
      })
      return items
    },
    commentGroupedSocialFootprints() {
      let items = []
      let types = UserRecordType.query().where('groupName', 'Social').orderBy('id').get()
      types.forEach(type => {
        if (UserRecord.query().where('type_id', type.id).exists()) {
          items.push({
            footprints: UserRecord.query().where('type_id', type.id).get(),
            comments: [],
            comment_grouped: {},
            type_id: type.id,
            type_name: type.name
          })

        }
      })
      items.map(item => {
        item.footprints.map(footprint => {
          if (!item.comments.includes(footprint.comment)) {
            item.comments.push(footprint.comment)
          }
        })
        item.comments.map((comment, index) => {
          item.comment_grouped[index] = UserRecord.query().where('type_id', item.type_id).where('comment', comment).withAll().get()
        })
        item.id = item.footprints[0].id

      })
      return items
    },
    newFootprints() {
      return UserRecord.query().where('is_new', true).get()
    },
    deleteFootprints() {
      return UserRecord.query().where('to_be_deleted', true).get()
    },
    updateFootprints() {
      return UserRecord.query().where('to_be_deleted', false).where('is_new', false).get()
    },
    commentGroupedEmotionalFootprints() {
      let items = []
      let types = UserRecordType.query().where('groupName', 'Emotional').orderBy('id').get()
      types.forEach(type => {
        if (UserRecord.query().where('type_id', type.id).exists()) {
          items.push({
            footprints: UserRecord.query().where('type_id', type.id).get(),
            comments: [],
            comment_grouped: {},
            type_id: type.id,
            type_name: type.name
          })

        }
      })
      items.map(item => {
        item.footprints.map(footprint => {
          if (!item.comments.includes(footprint.comment)) {
            item.comments.push(footprint.comment)
          }
        })
        item.comments.map((comment, index) => {
          item.comment_grouped[index] = UserRecord.query().where('type_id', item.type_id).where('comment', comment).withAll().get()
        })
        item.id = item.footprints[0].id

      })
      return items
    },
    filteredLearners() {
      if (this.searchText) {
        return User.query().search(this.searchText, {
          caseSensitive: false,
          threshold: 0.3,
          keys: ['first_name', 'last_name'],
          minMatchCharLength: 0
        }).where((user) => {
          return !this.newFootprintLearners.some(learner => user.id === learner.id)
        }).get()
      }
      return User.query().where((user) => {
        return !this.newFootprintLearners.some(learner => user.id === learner.id)
      }).get()
    },
    typeGroupedFootprints() {
      let items = {}
      this.ungroupedFootprintTypes.forEach(type => {
        if (UserRecord.query().where('type_id', type.id).exists()) {
          items[type.id] = {
            footprints: UserRecord.query().where('type_id', type.id).get(),
            comments: [],
            comment_grouped: {}
          }
          items[type.id].footprints.map(footprint => {
            if (!items[type.id].comments.includes(footprint.comment)) {
              items[type.id].comments.push(footprint.comment)
            }
          })
          items[type.id].comments.map((comment, index) => {
            items[type.id].comment_grouped[index] = UserRecord.query().where('type_id', type.id).where('comment', comment).withAll().get()
          })
        }
      })
      return items
    },
    footprints() {
      return UserRecord.query().orderBy('type_id').all()
    }
  },
  mounted() {
    UserRecord.deleteAll()
    UserRecordType.create({data: this.ungroupedFootprintTypes})
    this.loading = true
    UserRecord.FetchAllByModel({page: 1, limit: 9999}, this.model, this.model_id).then(() => {
      this.loading = false
    })
  }

};
</script>
