<template>
  <div>

    <b-collapse class="card"
                v-model="isOpen"
                animation="slide">
      <template #trigger="props">
        <div
            class="card-header mb-3"
            role="button"
            aria-controls="contentIdForA11y3">
          <p class="card-header-title is-size-4">
            {{ staticFootprintGroup.type_name }}
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
      </template>
      <Footprint v-for="(comment,index) in staticFootprintGroup.comments" :key="comment+index" :base-comment="comment"
                 :footprints="staticFootprintGroup.comment_grouped[index]" :sync-bit-flip="syncChildrenBitFlip"
                 :type_id="staticFootprintGroup.type_id"
                 class="pb-5 mx-3"
                 :type_name="staticFootprintGroup.type_name" @synced="checkSync(comment)"></Footprint>
    </b-collapse>
  </div>

</template>

<script>

import Footprint from "@/components/lessons/Footprint";

export default {
  name: "FootprintGroup",
  components: {Footprint},
  data() {
    return {
      newFootprintTypeId: null,
      footprintLearners: [],
      comment: null,
      activeFootprintTypeIds: [],
      newFootprints: [],
      syncTotal: 0,
      isOpen: true,
      lockedFootprintGroup: null,
      syncChildrenBitFlip: 0,
      loading: false
    };
  },

  props: {
    footprintGroup: {
      type: Object,
      required: true,
      default() {
        return Object
      }
    }, syncBitFlip: {
      type: Number,
      default() {
        return 0
      }
    },

  },
  watch: {
    syncBitFlip(newValue) {
      if (newValue === 1) {
        this.lockedFootprintGroup = JSON.parse(JSON.stringify(this.footprintGroup))
        this.syncChildrenBitFlip = 1
      }
    }
  },
  methods: {
    checkSync() {
      this.syncTotal++
      if (this.syncTotal === this.staticFootprintGroup.comments.length) {
        this.$emit('groupSynced')
      }

    },


  },
  computed: {
    staticFootprintGroup() {
      if (this.lockedFootprintGroup === null) {
        return this.footprintGroup
      }
      return this.lockedFootprintGroup
    }
  },
  mounted() {

  }

};
</script>
